body {
  background: var(--body);
  color: var(--dark-blue);
}

::-webkit-input-placeholder {
  opacity: 1;
  color: #575757;
}

:-moz-placeholder {
  opacity: 1;
  color: #575757;
}

::-moz-placeholder {
  opacity: 1;
  color: #575757;
}

:-ms-input-placeholder {
  opacity: 1;
  color: #575757;
}

/* Color Variable */
:root {
  --primary: #9461FC;
  --secondary: #EF1564;
  --body: #F5F5F5;
  --box: #35354F80;
  --grey: #FFFFFF;
  --light-grey: #FFFFFF29;
  --white: #1A192E;

  --gunmetal: #2E2E46;
  --crayola: #33334A;
  --charcoal: rgba(225, 225, 225, 0.80);

  --dark-blue: #1A192E;
  --solid-white: #FFFFFF;
}

/* Color Variable */


.site-nav ul li a {
  border: 2px solid #e7e7e7;
  background: rgba(26, 25, 46, 0.00);
  color: var(--dark-blue);
}

.site-nav ul li a:hover {
  color: var(--solid-white);
  border: 2px solid #e7e7e7;
}

.site-nav ul li a.active {
  color: var(--solid-white);
  border: 2px solid #e7e7e7;
  background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
}

.site-nav ul li a.active span svg {
  color: var(--solid-white);
}

.site-header {
  background: var(--solid-white);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
  border-bottom: 1px solid #e5e5e5;
}

.company-link .dropdown-toggle.btn {
  background: var(--solid-white);
  color: var(--dark-blue);
  transition: none;
}

.company-link .dropdown-toggle.btn:hover {
  color: var(--solid-white) !important;
}

.company-link .dropdown-toggle.btn a {
  color: var(--dark-blue);
}

.company-link .dropdown-toggle.btn:hover a {
  color: var(--solid-white) !important;
}

.company-link .dropdown-menu ul li a {
  transition: none;
}

.company-link .dropdown-menu ul li a:hover {
  color: var(--solid-white);
}

.user-nav .dropdown-menu ul li a:hover {
  color: var(--solid-white);
}

.hero-banner-left h1,
.hero-banner-left p {
  color: var(--solid-white);
}

.banner-right-video button {
  background: var(--solid-white);
}

.credits-icon {
  color: #747474;
}

.profile-stat-list ul li span {
  background: #C4C4CB;
  color: #7a7a7a;
}

.profile-stat-list ul li.active span {
  background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
  color: var(--solid-white);
}

.user-symbol {
  background: var(--dark-blue);
  color: var(--solid-white);
}

.departments .recent-conv-icon {
  background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
  color: var(--solid-white);
}

.recent-conv-list ul li:hover .next-icon {
  color: var(--solid-white);
}

.credit-icon {
  border: 2px solid #e7e7e7;
  background: var(--solid-white);
}

.tutorial-icon {
  background: #C4C4CB;
}

.tutorial-list ul li:hover .tutorial-icon {
  color: var(--solid-white);
}

.theme-btn {
  color: var(--solid-white);
}

.theme-btn:hover {
  color: var(--solid-white);
}

.theme-btn::before {
  background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
}

.join-facebook-bar h2,
.join-facebook-bar p {
  color: var(--dark-blue);
}

/* .site-footer{ background: var(--solid-white);} */
.footer-right ul li a {
  background: #E8E8E8;
  color: var(--dark-blue);
}

.footer-right ul li a:hover {
  color: var(--solid-white);
}

.useage-data-single:hover {
  background: var(--solid-white);
  border: 1px solid var(--secondary);
}

.useage-data-right .dropdown-toggle.btn {
  background: var(--solid-white);
  border: 2px solid #C4C4CB;
  color: var(--dark-blue);
}

.select-style .react-select__control {
  background: var(--solid-white) !important;
  border: 2px solid #C4C4CB !important;
}

.dashboard-block {
  background: var(--solid-white);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
  border: 1px solid #e5e5e5;
}

.useage-data-right .dropdown-menu ul li a {
  display: block;
  background: #E8E8E8;
  color: var(--dark-blue);
  border-radius: 50px;
  padding: 5px 10px;
  margin: 4px 0;
}

.useage-data-right .dropdown-menu ul li a:hover {
  background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
  color: var(--solid-white);
  color: var(--solid-white);
}

.inp-outer {
  background: #d9d9d9;
  color: var(--dark-blue);
}

.inp {
  background: var(--solid-white);
}

.input-wrap label {
  font-weight: 600;
}

.theme-btn.bdr {
  color: var(--dark-blue);
}

.theme-btn.bdr:hover {
  color: var(--solid-white);
}

.pro-stat {
  color: var(--solid-white) !important;
}

.department-single {
  border: 2px solid #ebebeb;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
}

.select-style.alt .react-select__control {
  background: var(--solid-white) !important;
}

.user-stack-single {
  border: 4px solid var(--solid-white);
}

.user-stack-single.last {
  background: var(--solid-white);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
}

.drop-style .dropdown-toggle.btn {
  border: 2px solid #C4C4CB;
  color: var(--dark-blue);
}

.back {
  background: rgba(39, 39, 62, 0.10);
}

.back:hover {
  color: var(--solid-white);
}

.drop-style .dropdown-menu ul li a:hover {
  background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
  color: var(--solid-white);
}

.message-txt {
  background: var(--solid-white);
  border: 1px solid #ebebeb;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
}

.msg-tags span {
  background: #dddddd;
}

.own-message .message-txt {
  color: var(--solid-white);
}

.embed-user-icon {
  background: var(--solid-white);
  color: var(--dark-blue);
}

.inp-icon .user-nav .dropdown-menu {
  background: var(--solid-white) !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
  border: 1px solid #ebebeb;
}

.act-menu .embed-user-icon {
  background: rgba(26, 25, 46, 0.12);
}

.act-menu:hover {
  background: #E7E7E7;
}

.act-menu-txt h6 {
  font-weight: 500;
}

.closePrev {
  color: var(--solid-white);
}

.single-staff {
  color: var(--solid-white);
}

.staff-txt {
  color: var(--dark-blue);
}

.single-staff.intern .staff-txt {
  color: var(--solid-white);
}

.single-staff .drop-style .dropdown-toggle.btn {
  color: var(--solid-white);
}

.staffBtn {
  background: #E7E7E7;
}

.staffBtn button.active {
  background: var(--solid-white);
}

.embed-top {
  background: #C4C4CB;
  font-weight: 600;
}

.embed-single {
  background: var(--solid-white);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
  border: 1px solid #ebebeb;
}

.embed-single .embed-user-icon {
  border: 2px solid #dbdbdb;
}

.embed-user .embed-user-icon {
  background: #dbdbdb;
}

.embed-block {
  background: var(--solid-white);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
  border: 1px solid #ebebeb;
}

.code-block {
  background: rgba(196, 196, 203, 0.40);
}

.embed-user-icon {
  border: 2px solid #dbdbdb;
}

.modalIcon {
  background: rgba(196, 196, 203, 0.40);
}

.log-option {
  color: var(--dark-blue);
}

.log-option a {
  color: var(--secondary);
}

.support-single p {
  color: var(--dark-blue);
}

.vidClose {
  color: var(--secondary);
  font-weight: 700;
}

.navStyle .nav-item a {
  background: var(--solid-white);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
}

.for-check {
  background: var(--solid-white);
  border: 2px solid #d9d9d9;
}

.custom-radio {
  background: var(--solid-white);
  border: 2px solid #d9d9d9;
}

.custom-radio .checkmark {
  background: #E7E7E7;
}

.custom-radio input:checked~.checkmark {
  color: var(--solid-white);
}

.custom-radio:has(> input:checked) {
  background: #E7E7E7;
  border: 2px solid #E7E7E7;
}

.dir-folder ul li {
  background: var(--solid-white);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
  border: 1px solid var(--solid-white);
}

.dir-folder ul li.active {
  background: var(--solid-white);
  color: var(--secondary);
  border: 1px solid var(--secondary);
}

.embed-user-txt h6 {
  opacity: 1;
  font-weight: 700;
}

.chat-box {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
  border: 2px solid #e9e9e9;
}

.chat-box-top {
  background: #EDEDED;
}

.message-wrap.auto-message .message-txt {
  background: #FFF;
  border: 1px solid var(--secondary);
}

.auto-header .user-nav .drop-icon {
  border: 2px solid #1A192E;
}

.typing {
  background: #F2F2F2 !important;
}

.auto-message .theme-accord .accordion-item {
  background: #F2F2F2 !important;
}

.auto-message .theme-accord .accordion-item:has(> .accordion-collapse.show) {
  background: #F2F2F2 !important;
}

.theme-accord .accordion-header button {
  color: #1A192E;
  font-weight: 700;
}

.react-select__option:hover {
  color: var(--solid-white) !important;
}

.react-select__option--is-selected {
  background: #EDEDED !important;
  color: #1A192E !important;
}

.chat-mode {
  background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
  color: var(--solid-white);
  border: none;
}

.chat-mode svg {
  color: var(--solid-white);
}

.drop-icon {
  border: 2px solid #d1d1d1;
  color: #505050;
}

.site-footer {
  background: var(--solid-white);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
  border-top: 1px solid #e5e5e5;
}

.flexLink:hover .flexLink-icon button {
  color: var(--solid-white) !important;
}

.flexLink:hover input,
.flexLink.active input {
  color: var(--solid-white) !important;
}

.flexLink.active .flexLink-icon button {
  color: var(--solid-white) !important;
}

.flexLink {
  padding: 0 20px;
}

.symbol {
  color: #FFF;
}