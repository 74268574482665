@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');

/* Reset CSS */
* {
  padding: 0px;
  margin: 0px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

*:focus {
  outline: 0;
}

html,
body {
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-size: 15px;
  font-family: 'Inter', sans-serif;
  min-height: 100vh;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

:focus {
  outline: 0;
}

a,
a:link {
  text-decoration: none;
  outline: none;
}

p {
  padding: 2px 0;
  margin: 0;
}

a:focus {
  outline: none !important;
}

:hover {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
}

.content-area-in {
  padding: 1.75rem;
}

img {
  border: 0;
  max-width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-weight: 400;
}

/* Reset CSS */


body.modal-open {
  height: 100vh;
  overflow: hidden;
  padding: 0 !important;
}

.modal-backdrop {
  background: rgba(26, 25, 46, 0.85) !important;
  backdrop-filter: blur(10px);
  opacity: 1 !important;
}

.container {
  max-width: 1310px;
}

.theme-btn {
  display: inline-block;
  border: none;
  cursor: pointer;
  background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
  border-radius: 50px;
  font-size: 15px;
  font-weight: 600;
  color: var(--white);
  padding: 8px 20px;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.theme-btn::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  opacity: 0;
  height: 100%;
  background: var(--white);
  transition: all 0.5s ease;
}

.theme-btn:hover {
  color: var(--secondary);
}

.theme-btn:hover::before {
  width: 100%;
  opacity: 1;
}

.theme-btn span {
  position: relative;
  z-index: 1;
}

.theme-btn.full {
  display: block;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.theme-btn svg {
  font-size: 18px;
  transform: translateY(-1px);
}

.theme-btn.grey {
  background: rgba(255, 255, 255, 0.20);
}

.theme-btn.grey:hover {
  color: var(--white);
}

.theme-btn.grey::before {
  opacity: 0;
  visibility: hidden;
}

.theme-btn.bdr {
  background: none;
  background: linear-gradient(var(--grey), var(--grey)) padding-box, linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%) border-box;
  border: 1px solid transparent;
}

.theme-btn.connected {
  background: var(--charcoal);
  color: #FFF !important;
  border: 1px solid var(--charcoal) !important;
}

.theme-btn.connected::before {
  opacity: 0;
}

.login-wrap {
  min-height: 100vh;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 110px 50px;
}

/* .login-box{ background: var(--box); width: 416px; margin: 0 auto; border-radius: 12px; padding: 30px;} */
.login-box {
  width: 100%;
  margin: 0 auto;
  padding: 30px;
  background: #FFF;
  border-radius: 15px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
  border: 1px solid #e5e5e5;
}

.symbol {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
  font-size: 40px;
}

.login-left {
  max-width: calc(100% - 500px);
  padding-right: 80px;
}

.login-left h2 {
  font-weight: 700;
}

.login-left h2 span {
  background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.login-right {
  max-width: 500px;
  width: 500px;
}

.login-box h2 {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.2px;
  padding-top: 22px;
  text-align: center;
}

.input-wrap {
  margin-top: 20px;
}

.input-wrap label {
  font-size: 15px;
  padding-bottom: 12px;
  display: block;
}

.inp-outer {
  padding: 2px;
  background: var(--light-grey);
  border-radius: 8px;
  position: relative;
}

.inp {
  width: 100%;
  background: var(--body);
  border-radius: 7px;
  border: none;
  color: var(--white);
  font-size: 15px;
  font-weight: 400;
  padding: 10px 16px;
}

.inp.radius {
  border-radius: 50px;
}

.inp-outer:has(> .inp:focus) {
  background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
}

textarea.inp {
  height: 150px;
  display: block;
}

.input-wrap.alt .inp-outer {
  border-radius: 50px;
}

.input-wrap.alt .inp {
  border-radius: 50px;
}

.inp-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  font-size: 22px;
}

/* .upgrade-tab-left {
  width: 15.625rem;
}

.upgrade-tab-right {
  width: calc(100% - 15.625rem);
  padding-left: 3.125rem;
} */
.upgrade-tab-in {

  margin-bottom: 1rem;
}

.upgrade-tab-block {
  background: white;
  padding: 1.5rem;
  border-radius: 1rem;
  margin-top: 1rem;
}

.upgrade-tab-block:first-child {
  margin-top: 0;
}

.upgrade-tab-block {
  padding: 1rem;
}

.simple-input {
  margin-top: 1.125rem;
}

.simple-input .label {
  display: block;
  padding-bottom: 0.375rem;
  color: var(--black-100);
  font-weight: 500;
}

.simple-input .input {
  background: var(--white-100);
  border: 0.0625rem solid gray;
  width: 100%;
  border-radius: 0.5rem;
  padding: 0.675rem 0.9375rem;
}

.simple-input .input:focus {
  border: 0.0625rem solid var(--black-40);
}

.upgrade-tab-block {
  background: white;
  padding: 1.5rem;
  border-radius: 1rem;
}

.site-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100;
}

.upgrade-tab-block {
  background: white;
  padding: 1.5rem;
  border-radius: 1rem;
  margin-top: 1rem;
}

.upgrade-tab-block:first-child {
  margin-top: 0;
}

.upgrade-tab {
  background: var(--white-100);
  border-radius: 1rem;
  padding: 1.5rem;
  box-shadow: var(--shadow-1);
}

.upgrade-tab-left {
  width: 15.625rem;
}

.upgrade-tab-right {
  width: calc(100% - 15.625rem);
  padding-left: 3.125rem;
}

.upgrade-tab-in {
  margin-bottom: 1.5rem;
}

.upgrade-tab-in.first {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 31.25rem;
  text-align: center;
  background: var(--white-100);
  padding: 1.5rem;
  border-radius: 1rem;
}

.upgrade-tab {
  display: flex;
  align-items: flex-start;
  margin-top: 2.5rem;
}

.upgrade-tab.large .upgrade-tab-left {
  width: 21.875rem;
}

.upgrade-tab.large .upgrade-tab-right {
  width: calc(100% - 21.875rem);
}

.upgrade-tab .nav {
  display: block;
}

.upgrade-tab .nav-item {
  margin-bottom: 0.625rem;
}

.upgrade-tab .nav-link {
  color: var(--black-100);
  font-weight: 500;
  border-radius: 0.5rem;
}

.upgrade-tab .nav-link:hover,
.upgrade-tab .nav-link.active {
  background: lightgray;
}

.upgrade-tab-block {
  background: white;
  padding: 1.5rem;
  border-radius: 1rem;
  margin-top: 1rem;
}

.upgrade-tab-block:first-child {
  margin-top: 0;
}

.upload-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 7.5rem;
  border: 0.0625rem dashed gray;
  border-radius: 0.5rem;
  background: var(--primary-light);
  text-align: center;
  font-weight: 500;
  color: black;
  position: relative;
}

.upload-box .upload-box-icon {
  font-size: 1.875rem;
}

.upload-box p {
  font-size: 0.8125rem;
  line-height: 1rem;
}

.upload-box input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
}

.help-top {
  margin-top: 2.5rem;
}

.help-card-single {
  border-radius: 0.625rem;
  padding: 1.25rem;
  height: 12.5rem;
  background: var(--body-color);
  border: 0.0625rem solid gray;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: var(--black-80);
  margin-top: 1.5rem;
}

.help-card-icon {
  font-size: 1.875rem;
  margin-bottom: 0.9375rem;
}

.help-card-icon img {
  max-width: 50px !important;
  padding: 0 !important;
  height: auto !important;
  border-radius: 0 !important;
}



.or {
  position: relative;
  font-size: 12px;
  text-align: center;
}

.or span {
  display: inline-block;
  background: var(--body);
  padding: 0 12px;
  position: relative;
  z-index: 1;
}

.or::after {
  content: '';
  position: absolute;
  left: 0;
  top: 8px;
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.14);
}

.social-sign {
  position: relative;
  margin-top: 20px;
}

.social-sign button {
  width: 100%;
  text-align: center;
  background: var(--crayola);
  border: none;
  color: #FFF;
  padding: 9px 30px;
  border-radius: 50px;
  font-size: 14px;
}

.social-sign button:hover {
  background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%) border-box;
}

.social-sign-icon {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.5s ease;
}

.log-option {
  margin-top: 20px;
  color: rgba(255, 255, 255, 0.70);
}

.log-option a {
  color: var(--white);
}

.log-option a:hover {
  color: var(--primary);
}

.site-wrapper {
  min-height: calc(100vh - 78px);
  padding-bottom: 100px;
  padding-top: 114px;
}

.site-footer-main {
  height: 78px;
}

.site-container {
  padding: 0 50px;
}

.site-header {
  border-bottom: 2px solid rgba(255, 255, 255, 0.08);
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 9999;
  background: var(--body);
}

.site-header-main {
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo img {
  width: 170px;
  max-height: 60px;
}

.header-right {
  display: flex;
  align-items: center;
}

.site-nav ul {
  display: flex;
  align-items: center;
}

.site-nav ul li {
  display: inline-block;
  margin-left: 16px;
}

.site-nav ul li a {
  display: flex;
  align-items: center;
  color: var(--white);
  height: 44px;
  border: 2px solid #363547;
  border-radius: 100px;
  padding: 0 15px;
  font-weight: 500;
  transition: none;
}

.site-nav ul li a:hover {
  background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 100%);
  border: 2px solid var(--grey);
}

.site-nav ul li a span {
  display: flex;
  font-size: 20px;
  padding-right: 7px;
  opacity: 0.6;
}

.site-nav ul li a.active {
  background: var(--white);
  color: var(--grey);
}

.site-nav ul li a.active span {
  opacity: 1;
}

.site-nav ul li a.active span svg {
  color: var(--secondary);
}

.profile-link {
  margin-left: 120px;
  display: flex;
  align-items: center;
}

.company-link {
  padding: 1px;
  border-radius: 50px;
  background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
}

.company-link .dropdown-toggle.btn {
  border-radius: 50px;
  background: var(--grey);
  border: none;
  color: var(--white);
  display: flex;
  align-items: center;
  height: 40px;
}

.company-link .dropdown-toggle.btn:hover {
  background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 100%);
}

.company-logo {
  margin-right: 7px;
  display: inherit;
}

.company-logo img {
  width: 20px;
  height: 20px;
  object-fit: cover;
  border-radius: 50px;
}

.company-link .dropdown-menu {
  width: 234px;
  background: var(--grey);
  border-radius: 12px;
  padding: 12px;
  background: linear-gradient(var(--grey), var(--grey)) padding-box, linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%) border-box;
  border: 1px solid transparent;
  margin-top: 16px;
}

.company-link .dropdown-menu ul li {
  margin-top: 12px;
}

.company-link .dropdown-menu ul li:first-child {
  margin-top: 0;
}

.company-link .dropdown-menu ul li a {
  display: flex;
  height: 34px;
  align-items: center;
  background: var(--charcoal);
  color: var(--white);
  border-radius: 50px;
  font-size: 14px;
  padding: 0 10px;
}

.company-link .dropdown-menu ul li a.active {
  color: #FFF !important;
}

.company-link .dropdown-menu ul li a img {
  width: 22px;
}

.company-link .dropdown-menu ul li a span {
  display: block;
  width: calc(100% - 30px);
  padding-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.company-link .dropdown-menu ul li a:hover {
  background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
}

.user-nav {
  margin-left: 20px;
}

.user-img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-nav .dropdown-toggle.btn {
  border: none;
  background: none;
  padding: 0;
}

.user-nav .dropdown-toggle::after {
  display: none;
}

.user-img-in {
  width: 34px;
  height: 34px;
  border-radius: 100%;
  overflow: hidden;
}

.user-img-in img {
  width: 34px;
  height: 34px;
  object-fit: cover;
}

.user-nav .dropdown-menu {
  width: 190px;
  background: var(--grey);
  border-radius: 12px;
  padding: 12px;
  background: linear-gradient(var(--grey), var(--grey)) padding-box, linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%) border-box;
  border: 1px solid transparent;
  margin-top: 16px;
}

.user-nav .dropdown-menu ul li {
  margin-top: 12px;
}

.user-nav .dropdown-menu ul li:first-child {
  margin-top: 0;
}

.user-nav .dropdown-menu ul li a {
  display: flex;
  height: 34px;
  align-items: center;
  background: var(--charcoal);
  color: var(--white);
  border-radius: 50px;
  font-size: 14px;
  padding: 0 10px;
  letter-spacing: -0.14px;
  gap: 0 5px;
}

.user-nav .dropdown-menu ul li a span {
  display: inherit;
  font-size: 18px;
  padding-right: 8px;
}

.user-nav .dropdown-menu ul li a:hover {
  background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
}


.hero-banner {
  height: 390px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: url('../src/assets/images/hero-banner.jpg') center center no-repeat;
  background-size: cover;
  border-radius: 12px;
  padding: 0 40px;
  position: relative;
  overflow: hidden;
}

.hero-banner-left {
  max-width: 520px;
  position: relative;
  z-index: 4;
}

.hero-banner-left h1 {
  font-size: 34px;
  font-weight: 700;
}

.hero-banner-left p {
  font-size: 17px;
  opacity: 0.7;
  color: var(--white);
}

.hero-banner-right {
  width: 560px;
  position: relative;
  z-index: 4;
}

.banner-right-video {
  background: rgba(255, 255, 255, 0.10);
  border-radius: 14px;
  padding: 5px;
}

.banner-right-video-in {
  height: 318px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: url('../src/assets/images/video-bg.jpg') no-repeat center center; */
  background-size: cover;
  border-radius: 12px;
  overflow: hidden;
}

.banner-right-video button {
  background: var(--white);
  border: none;
  font-weight: 600;
  padding: 9px 12px;
  border-radius: 50px;
  transition: all 0.5s ease;
}

.banner-right-video button svg {
  color: var(--secondary);
}

.banner-right-video button:hover {
  transform: scale(1.05);
}

.hero-banner-left button {
  background: var(--white);
  border: none;
  font-weight: 600;
  padding: 9px 12px;
  border-radius: 50px;
  transition: all 0.5s ease;
  margin-top: 15px;
}

.hero-banner-left button svg {
  color: var(--secondary);
}

.hero-banner-left button:hover {
  transform: scale(1.05);
}

.hero-banner-video {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.hero-banner-video::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #9461FC -11.62%, #EF1564 62.73%);
  opacity: 0.6;
}

.hero-banner-video video {
  object-fit: cover;
  height: 390px;
}

.VideoModal .modal-dialog {
  max-width: 1000px;
}

.VideoModal .modal-content {
  background: var(--grey);
  border-radius: 15px;
  background: linear-gradient(var(--grey), var(--grey)) padding-box, linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%) border-box;
  border: 1px solid transparent;
}

.VideoModal.show-modal {
  background: rgba(0, 0, 0, 0.6);
}

.modalVidWrap {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  border-radius: 15px;
  overflow: hidden;
}

.modalVid iframe {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.vidClose {
  position: absolute;
  right: 0;
  top: -40px;
  cursor: pointer;
  font-size: 30px;
}

.dashboard-wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.dashboard-left {
  width: 25%;
}

.dashboard-right {
  width: 75%;
  padding-left: 25px;
}

.dashboard-block {
  border-radius: 12px;
  border: 2px solid rgba(255, 255, 255, 0.08);
  background: var(--grey);
  padding: 16px;
  margin-top: 25px;
}

.profile-stat-top {
  display: flex;
  align-items: center;
}

.circlePro {
  width: 52px;
  height: 52px;
  position: relative;
}

.circlePro-img {
  position: absolute;
  left: 5px;
  top: 5px;
  width: 42px;
  height: 42px;
  border-radius: 100%;
  overflow: hidden;
}

.circlePro-img {
  width: 42px;
  height: 42px;
  object-fit: cover;
}

.CircularProgressbar-path {
  stroke: var(--secondary) !important;
}

.CircularProgressbar-trail {
  stroke: #3c3c51 !important;
}

.profile-stat-details {
  width: calc(100% - 52px);
  padding-left: 15px;
}

.profile-stat-details h5 {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}

.profile-stat-details p {
  margin: 0;
  padding: 3px 0 0 0;
  font-size: 13px;
  opacity: 0.7;
}

.profile-stat-list ul li {
  display: flex;
  align-items: center;
  margin-top: 22px;
}

.profile-stat-list ul li span {
  width: 22px;
  height: 22px;
  border-radius: 100%;
  background: #FFFFFF1F;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin-right: 10px;
}

.profile-stat-list ul li.active span {
  background: var(--white);
  color: var(--secondary);
}

.credits .title {
  font-size: 16px;
  opacity: 0.7;
}

.credit-list ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
}

.credit-icon {
  width: 48px;
  height: 48px;
  background: var(--white);
  border-radius: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.credit-details {
  width: calc(100% - 48px);
  padding-left: 15px;
}

.credit-details h3 {
  font-size: 20px;
  font-weight: 500;
}

.credit-details h3 span {
  font-weight: 400;
  font-size: 14px;
  color: #B0B0B0;
}

.credit-progress {
  position: relative;
  height: 14px;
  background: #494963;
  border-radius: 50px;
}

.credit-progress-bar {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
  border-radius: 50px;
}

.credit-progress-bar::after {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: var(--white);
  position: absolute;
  right: 2px;
  top: 2px;
}

.company-name {
  max-width: 84px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tutorial .title {
  font-size: 16px;
  opacity: 0.7;
}

.tutorial-list ul li {
  margin-top: 30px;
}

.tutorial-list ul li a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--white);
}

.tutorial-icon {
  width: 56px;
  height: 36px;
  background: #3B3B52;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.tutorial-icon svg {
  opacity: 0.7;
}

.tutorial-details {
  width: calc(100% - 56px);
  padding-left: 12px;
}

.tutorial-details h5 {
  font-size: 16px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tutorial-details p {
  font-size: 14px;
  opacity: 0.7;
  padding: 0;
  margin: 0;
}

.tutorial-list ul li:hover .tutorial-icon {
  background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
}

.credits-item {
  display: flex;
  align-items: center;
}

.credits-icon {
  font-size: 30px;
  padding-right: 15px;
}

.credits-item h2 {
  font-size: 26px;
  font-weight: 600;
}

.credits-item p {
  font-size: 16px;
  opacity: 0.7;
}

.recent-conv h2 {
  font-size: 17px;
  font-weight: 600;
}

.recent-conv-list ul li {
  margin-top: 30px;
  position: relative;
}

/* .recent-conv-list ul li::after{ content: ''; position: absolute; left: 0; bottom: -4px; width: 0; height: 1px; background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%); transition: all 0.5s ease;} */
.recent-conv-list ul li:hover::after {
  width: 100%;
}

.recent-conv-list ul li a {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.recent-conv-left {
  display: flex;
  align-items: center;
  width: calc(100% - 100px);
}

.recent-conv-icon {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  position: relative;
}

.recent-conv-icon img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  object-fit: cover;
}

.user-symbol {
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: -5px;
  right: -5px;
  border-radius: 100%;
  background: var(--grey);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  font-size: 12px;
}

.recent-conv-details {
  width: calc(100% - 40px);
  padding-left: 15px;
  color: var(--white);
}

.recent-conv-details h5 {
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.recent-conv-details p {
  opacity: 0.7;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.next-icon {
  width: 30px;
  height: 30px;
  background: var(--charcoal);
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-size: 20px;
  cursor: pointer;
}

.recent-conv-list ul li:hover .next-icon {
  background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
}

.departments h2 {
  font-size: 17px;
  font-weight: 600;
}

.departments .recent-conv-icon {
  background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-size: 25px;
}

.departments {
  height: 359px;
}

.useage-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.useage-top h2 {
  font-size: 17px;
  font-weight: 600;
}

.useage-top p {
  opacity: 0.7;
}

.useage-data-single {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 0;
  border: 1px solid var(--grey);
  border-radius: 10px;
  transition: all 0.5s ease;
}

.useage-data-single:first-child {
  margin-top: 15px;
}

.useage-data-single:hover {
  background: linear-gradient(#323249, #323249) padding-box, linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%) border-box;
  border: 1px solid transparent;
  padding-left: 14px;
  padding-right: 14px;
}

.useage-data-left {
  display: flex;
  align-items: center;
}

.useage-data-img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  overflow: hidden;
}

.useage-data-img img {
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.useage-data-cont {
  padding-left: 12px;
}

.useage-data-cont h5 {
  font-size: 16px;
  font-weight: 500;
}

.useage-data-cont p {
  font-size: 14px;
  opacity: 0.7;
  margin: 0;
  padding: 0;
}

.useage-data-right {
  display: flex;
  align-items: center;
}

.useage-data-right h5 {
  font-size: 16px;
  font-weight: 500;
}

.useage-data-right h5 span {
  font-weight: 400;
  color: #B0B0B0;
  font-size: 14px;
}

.useage-data-right p {
  font-size: 14px;
  opacity: 0.7;
  margin: 0;
  padding: 0;
}

.useage-data-right>div {
  margin-left: 50px;
}

.useage-data-right .dropdown-toggle.btn {
  width: 28px;
  height: 28px;
  border: 2px solid #FFFFFF33;
  border-radius: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  margin: 0;
  padding: 0;
  font-size: 18px;
}

.useage-data-right .dropdown-toggle.btn::after {
  display: none;
}

.useage-data-right .dropdown-menu {
  background: var(--grey);
  border-radius: 12px;
  padding: 12px;
  background: linear-gradient(var(--grey), var(--grey)) padding-box, linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%) border-box;
  border: 1px solid transparent;
  margin-top: 6px;
}

.useage-data-right .dropdown-menu ul li a {
  font-size: 13px;
  color: var(--white);
}

.useage-data-right .dropdown-menu ul li a:hover {
  color: var(--primary);
}


.drop-style .dropdown-toggle.btn {
  width: 28px;
  height: 28px;
  border: 2px solid #FFFFFF33;
  border-radius: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  margin: 0;
  padding: 0;
  font-size: 18px;
}

.drop-style .dropdown-toggle.btn::after {
  display: none;
}

.drop-style .dropdown-menu {
  background: var(--grey);
  border-radius: 12px;
  padding: 12px;
  background: linear-gradient(var(--grey), var(--grey)) padding-box, linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%) border-box;
  border: 1px solid transparent;
  margin-top: 6px;
  left: inherit !important;
  right: 0 !important;
}

.drop-style .dropdown-menu ul li a {
  font-size: 13px;
  color: var(--white);
  background: var(--charcoal);
  display: flex;
  align-items: center;
  border-radius: 50px;
  min-height: 34px;
  padding: 0 10px;
}

.drop-style .dropdown-menu ul li a svg {
  font-size: 18px;
  margin-right: 5px;
}

.drop-style .dropdown-menu ul li:first-child {
  margin-top: 0;
}

.drop-style .dropdown-menu ul li {
  margin-top: 8px;
}

.drop-style .dropdown-menu ul li a:hover {
  color: var(--primary);
}

.webnair {
  text-align: center;
  height: 440px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.webnair h2 {
  font-size: 20px;
  font-weight: 700;
}

.webnair p {
  opacity: 0.7;
  /* width: 350px; */
  margin: 0 auto;
}

.modal .theme-btn {
  height: 41px;
  min-width: 100px;
}

.photo-frame {
  padding: 4px;
  border-radius: 12px;
  background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
  width: 330px;
  margin: 0 auto;
  margin-top: 20px;
}

.photo-frame img {
  border-radius: 12px;
}

.join-facebook {
  height: 440px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: none;
  background: linear-gradient(180deg, #CFCAE5 0%, #BAB2DE 100%);
}

.join-facebook-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 30px;
}

.join-facebook-bar h2 {
  color: var(--grey);
  font-size: 20px;
  font-weight: 700;
}

.join-facebook-bar p {
  color: var(--grey);
  opacity: 0.7;
  font-size: 16px;
}

.site-footer-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.site-footer p {
  font-size: 14px;
}

.site-footer p span {
  opacity: 0.7;
}

.site-footer p a {
  opacity: 1.0 !important;
  color: #119BFF;
}

.footer-right ul {
  display: flex;
  align-items: center;
  height: 100%;
}

.footer-right ul li {
  margin-left: 12px;
}

.footer-right ul li a {
  display: flex;
  align-items: center;
  color: var(--white);
  background: #26253A;
  padding: 10px 14px;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
}

.footer-right ul li a::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
  transition: all 0.5s ease;
}

.footer-right ul li a:hover::after {
  width: 100%;
}

.footer-right ul li a span {
  position: relative;
  z-index: 1;
}

.footer-right ul li a svg {
  margin-right: 2px;
}

.mobMenu {
  border-radius: 8px;
  background: var(--Light-Grey, rgba(255, 255, 255, 0.16));
  width: 50px;
  height: 40px;
  margin-left: 15px;
  display: none;
  color: var(--white);
  font-size: 22px;
}

.select-style .react-select__control {
  border-radius: 100px !important;
  border: 2px solid rgba(255, 255, 255, 0.20) !important;
  background: #27273E !important;
  box-shadow: none !important;
  min-width: 157px !important;
}

.select-style .react-select__indicator-separator {
  display: none !important;
}

.react-select__input-container {
  margin: 0 !important;
  padding: 0 !important;
  color: var(--white) !important;
}

.react-select__menu {
  background: var(--grey);
  border-radius: 12px;
  padding: 0 !important;
  background: linear-gradient(var(--grey), var(--grey)) padding-box, linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%) border-box;
  border: 1px solid transparent;
  overflow: hidden;
  z-index: 999 !important;
}

.react-select__menu-list {
  padding: 0 !important;
}

.react-select__single-value {
  color: var(--white) !important;
  font-size: 14px;
  padding-left: 10px;
}

.react-select__option {
  background: none !important;
  font-size: 13px !important;
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

.react-select__option:hover {
  background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%) !important;
}

.react-select__option--is-selected {
  background: #000 !important;
}

.react-select__input {
  opacity: 0 !important;
}


.hero-banner-2 {
  height: 700px !important;
  margin: -41px -50px 0 -50px;
  border-radius: 0;
  padding-bottom: 250px !important;
}

.hero-banner-2 .hero-banner-video video {
  height: 700px !important;
}

.dashboard-wrap-v2 {
  position: relative;
  z-index: 2;
  margin-top: -250px;
}

.hero-banner-2 .hero-banner-video::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 200px;
  background: linear-gradient(180deg, rgba(26, 25, 46, 0.00) 49.95%, #1A192E 77.85%);
  z-index: 1;
}

.hero-banner-2 .hero-banner-left {
  max-width: 630px;
}

.hero-banner-right .dashboard-block {
  padding: 10px 25px;
  margin: 12px 0;
}

.hero-banner-right .credits-item {
  justify-content: space-between;
}

.credits-item-left {
  display: flex;
}

.hero-banner-right .credits:hover .next-icon {
  background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
}

.hero-banner-4 {
  justify-content: center;
}

.hero-banner-4 .hero-banner-left {
  max-width: 633px !important;
  text-align: center;
}

.hero-banner-5 .hero-banner-video::after {
  background: linear-gradient(178deg, rgba(26, 25, 46, 0.00) -20.72%, #1A192E 50.03%);
}

.hero-banner-6 .hero-banner-video::after {
  background: linear-gradient(178deg, rgba(26, 25, 46, 0.00) -20.72%, #1A192E 50.03%);
}

.hero-banner-6 .hero-banner-right {
  width: auto;
}

.hero-banner-6 {
  align-items: flex-start;
  padding-top: 50px !important;
}

.dashboard-wrap-v6 {
  margin-top: -550px;
  position: relative;
  z-index: 2;
}

.var-6 .site-header .company-link {
  display: none;
}

.login-footer {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 2;
  background: var(--body);
}

.login-header .site-header {
  background: none;
  border: none;
  box-shadow: none;
}

.company-empty {
  max-width: 600px;
  margin: 0 auto;
}

.company-block-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.company-block-title h3 {
  font-size: 22px;
  font-weight: 600;
}

.company-block-title p {
  opacity: 0.7;
}

.create-box {
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
}

.create-box h3 {
  font-size: 17px;
  font-weight: 500;
  padding-bottom: 30px;
}

.webnair-block {
  border-radius: 12px;
  padding: 20px;
  background: var(--grey);
  margin: 25px 0 0 0;
}

.webnair-block-img {
  padding: 4px;
  background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
  border-radius: 6px;
}

.webnair-block-img img {
  border-radius: 5px;
}

.webnair-block-right h3 {
  font-size: 19px;
  font-weight: 500;
}

.webnair-block-right p {
  opacity: 0.7;
  line-height: 20px;
  padding: 10px 0 0 0;
}

.facebook-block {
  border-radius: 12px;
  border: 2px solid rgba(255, 255, 255, 0.08);
  background: linear-gradient(180deg, #CFCAE5 0%, #BAB2DE 100%);
  padding: 20px;
  margin-top: 20px;
}

.facebook-block-left {
  text-align: center;
}

.facebook-block-right h3 {
  color: var(--grey);
  font-size: 20px;
  font-weight: 700;
  letter-spacing: -0.2px;
}

.facebook-block-right p {
  color: var(--grey);
  opacity: 0.7;
  padding: 10px 0 0 0;
}

.file-field {
  position: relative;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: linear-gradient(var(--grey), var(--grey)) padding-box, linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%) border-box;
  border: 2px solid transparent;
  padding: 0 15px
}

.file-field input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  opacity: 0;
}

.file-field p {
  font-size: 13px;
  opacity: 0.7;
}

.file-field h6 {
  font-size: 15px;
  font-weight: 500;
}

.company-opt ul li {
  margin-top: 25px;
  height: 74px;
  background: var(--grey);
  border-radius: 12px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.company-opt-left {
  display: flex;
  align-items: center;
}

.company-opt-left h6 {
  font-size: 17px;
  font-weight: 600;
}

.company-opt-left p {
  opacity: 0.7;
  font-size: 14px;
  padding: 0;
}

.company-opt-details {
  padding-left: 10px;
}

.titleBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.titleBar-left h2 {
  font-size: 22px;
  font-weight: 500;
}

.titleBar-left p {
  opacity: 0.7;
}

.titleBar-right {
  display: flex;
  align-items: center;
}

.titleBar-right .input-wrap {
  margin-top: 0;
}

.titleBar-right .inp-outer {
  border-radius: 50px;
}

.titleBar-right .inp {
  border-radius: 50px;
}

.inp-outer.icon-left .inp-icon {
  right: inherit;
  left: 12px;
  margin-top: -2px;
}

.inp-outer.icon-left .inp {
  padding-left: 40px;
}

.titleBar-right>* {
  margin-left: 10px;
}

.department-list {
  max-width: 1200px;
  margin: 0 auto;
}

.department-single {
  background: var(--grey);
  border-radius: 12px;
  margin-top: 30px;
  border: 2px solid var(--body);
}

.department-single:hover {
  background: linear-gradient(var(--grey), var(--grey)) padding-box, linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%) border-box;
  border: 2px solid transparent;
}

.department-single-img {
  height: 170px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  overflow: hidden;
  position: relative;
}

.department-single-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.department-single-txt {
  text-align: center;
  padding: 16px;
}

.department-single-txt h4 {
  font-size: 18px;
  font-weight: 600;
}

.department-single-txt p {
  font-size: 14px;
  opacity: 0.7;
}

.department-single-img-hov {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(26, 25, 46, 0.70);
  backdrop-filter: blur(8px);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 0 10px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
}

.round-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  color: #FFF;
  background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
}

.department-single:hover .department-single-img-hov {
  opacity: 1;
  visibility: visible;
}

.select-style.alt .react-select__control {
  background: var(--body) !important;
}

.select-style.alt .react-select__placeholder {
  color: var(--white);
}

.department-single.alt {
  padding: 16px;
  min-height: 275px;
}

.department-single.alt .department-single-txt {
  text-align: left;
  padding: 0;
}

.department-single.alt .department-single-txt p {
  font-size: 14px;
  padding: 10px 0 0 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.department-single.alt .department-single-txt h4 {
  font-size: 16px;
}

.department-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 25px;
}

.pro-stat {
  display: inline-block;
  border-radius: 50px;
  background: rgba(111, 111, 111, 0.70);
  padding: 3px 10px;
  font-size: 14px;
}

.pro-stat.green {
  background: #55B56A;
}

.pro-stat.violet {
  background: var(--primary);
}

.user-stack {
  display: flex;
  align-items: center;
}

.user-stack-single {
  width: 44px;
  height: 44px;
  border: 4px solid #27273E;
  border-radius: 100%;
  overflow: hidden;
  margin: 0 0 0 -19px;
}

.user-stack-single:first-child {
  margin-left: 0;
}

.user-stack-single img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-stack-single.last {
  background: var(--white);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.user-stack-single.last span {
  background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  font-weight: 700;
}

.department-single.alt .user-stack {
  margin-top: 50px;
}

.back {
  background: var(--grey);
  border-radius: 50px;
  color: var(--white);
  padding: 10px 15px;
}

.back:hover {
  background: var(--primary);
}

.back svg {
  font-size: 20px;
}

.account-wrap {
  display: flex;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
}

.account-left {
  width: 240px;
}

.account-right {
  width: 600px;
  margin-left: 100px;
}

.account-right.large {
  width: 700px;
}

.account-right.larger {
  width: 780px;
}

.account-nav ul li {
  margin-top: 15px;
}

.account-nav ul li:first-child {
  margin-top: 0;
}

.account-nav ul li a {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 11px 10px;
  color: var(--white);
  font-weight: 500;
  border-radius: 50px;
}

.account-nav ul li a.active {
  background: var(--charcoal);
}

.account-nav ul li a svg {
  font-size: 22px;
  margin-right: 10px;
}


.account-nav ul li.has-subMenu a {
  border-radius: 0 !important;
}

.whiteMenu {
  border-radius: 10px;
  overflow: hidden;
  margin-top: 25px;
  padding-bottom: 15px;
}

.whiteMenu ul li a {
  padding-top: 0;
  padding-bottom: 0;
  background: none !important;
}

.whiteMenu li a.active {
  background: none !important;
}

.whiteMenu ul {
  padding-left: 15px;
  padding-right: 15px;
}

.subMenu {
  padding-top: 10px;
}

.subMenu ul li a {
  font-size: 13px;
  padding: 7px 9px;
}

.subMenu ul li {
  margin: 0 !important;
}

.subMenu ul li a.active {
  background: #cdcdcd !important;
}

.subMenu ul li a svg {
  font-size: 18px;
}

.whiteMenu:has(a.active) {
  background: var(--charcoal);
}

.theme-table tr td,
.theme-table tr th {
  padding: 8px 10px;
}

.theme-table tr th {
  background: var(--charcoal);
}

.theme-table .theme-btn {
  color: #FFF !important;
  font-size: 12px !important;
}

.account-title {
  text-transform: uppercase;
  font-weight: 600;
  opacity: 0.8;
  font-size: 15px;
}

.info-img {
  position: relative;
  width: 104px;
  height: 104px;
  border-radius: 100%;
  margin: 0 auto;
  margin-top: 30px;
}

.info-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
}

.edit-img-btn {
  width: 36px;
  height: 36px;
  background: var(--white);
  border-radius: 100%;
  position: absolute;
  top: 0;
  right: 0;
  border: 3px solid var(--grey);
  color: var(--secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.inp.alt {
  background: var(--grey);
}

.settings-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.14);
}

.settings-block:last-child {
  border: none;
  padding-bottom: 0;
}

.settings-block-left h6 {
  font-size: 14px;
  font-weight: 600;
}

.settings-block-left p {
  font-size: 13px;
  opacity: 0.7;
}

.settings-block-left p a {
  color: #9461FC;
  opacity: 1 !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #636374;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
}

input:checked+.slider:before {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.settings-block-left.alt {
  align-self: stretch;
  width: 100%;
}

.theme-btn-orange {
  background: #F47730;
}

.theme-btn-red {
  background: #EF153C;
}

.integrations-single {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.integration-left {
  display: flex;
  align-items: center;
}

.integrations-single {
  border-bottom: 1px solid rgba(255, 255, 255, 0.14);
  padding: 20px 0;
}

.integrations-single:first-child {
  padding-top: 0;
}

.integrations-single:last-child {
  border: none;
  padding-bottom: 0;
}

.integrations-single h6 {
  font-size: 14px;
  font-weight: 600;
}

.integrations-single p {
  font-size: 13px;
  opacity: 0.7;
  padding: 0;
}

.integration-txt {
  padding-left: 10px;
}

.manage-block-single {
  background: var(--grey);
  border-radius: 12px;
  padding: 16px 18px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.manage-block-left {
  display: flex;
  align-items: center;
}

.manage-block-icon {
  width: 42px;
  height: 42px;
  border-radius: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #45455C;
  font-size: 22px;
}

.manage-block-txt {
  padding-left: 10px;
}

.manage-block-single h6 {
  font-size: 15px;
  font-weight: 600;
}

.manage-block-single p {
  font-size: 14px;
  opacity: 0.7;
}

.group-icon {
  font-size: 22px;
}

.group-icon span {
  opacity: 0.7;
  display: inline-block;
  margin-left: 5px;
  cursor: pointer;
}

.sm-modal .modal-dialog {
  max-width: 500px;
}

.sm-modal .modal-body {
  padding: 20px;
}

.modal-content .titleBar-left h2 {
  font-size: 17px;
}

.modal-content .titleBar-left p {
  font-size: 13px;
}

.select-style.square .react-select__control {
  border-radius: 12px !important;
}

.modalClose {
  font-size: 22px;
  cursor: pointer;
}

.modalIcon {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #45455C;
  font-size: 32px;
  margin: 0 auto;
}

.btnGroup .theme-btn {
  margin: 0 4px;
}

.support-single {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: var(--grey);
  border-radius: 12px;
  min-height: 180px;
  margin-top: 25px;
  text-align: center;
  border: 2px solid var(--grey);
  padding: 15px;
}

.support-single:hover {
  background: linear-gradient(var(--grey), var(--grey)) padding-box, linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%) border-box;
  border: 1px solid transparent;
}

.support-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--charcoal);
  width: 48px;
  height: 48px;
  border-radius: 100%;
}

.support-icon {
  font-size: 20px;
}

.support-single p {
  padding: 12px 0 0 0;
  font-size: 13px;
}

.theme-accord .accordion-button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.theme-accord .accordion-item {
  margin-top: 15px;
  background: var(--grey) !important;
  border: none !important;
  border-radius: 12px !important;
}

.theme-accord .accordion-header button {
  background: none !important;
  border: none !important;
  color: #FFF;
  box-shadow: none !important;
}

.theme-accord .accordion-body {
  color: var(--white);
  padding-top: 0;
}

.theme-accord .accordion-body p {
  font-size: 14px;
  opacity: 0.7;
}

.theme-accord .accordion-button::after {
  width: 28px;
  height: 28px;
  background: url('../src/assets/images/icon/acc-open.png') no-repeat;
}

.theme-accord .accordion-button:not(.collapsed)::after {
  background: url('../src/assets/images/icon/acc-close.png') no-repeat;
}

.account-wrap.for-post .account-left {
  width: 350px;
}

.account-wrap.for-post .account-right {
  width: calc(100% - 350px);
  margin: 0;
  padding-left: 50px;
}

.tabNav .nav-item {
  margin-top: 20px;
}

.tabNav .nav-item:first-child {
  margin-top: 0;
}

.tabNav .nav-link {
  color: var(--white);
  font-size: 14px;
  border-radius: 50px;
  padding: 13px 20px;
}

.tabNav .nav-link.active {
  background: var(--grey);
}

.outer-container {
  max-width: 1200px;
  margin: 0 auto;
}

.post-block h2 {
  font-size: 19px;
  font-weight: 600;
}

.post-block p {
  font-size: 14px;
  opacity: 0.7;
  padding: 15px 0 0 0;
}

.post-block-img {
  border: 4px solid rgba(255, 255, 255, 0.10);
  border-radius: 10px;
  margin-top: 20px;
}

.post-block-img img {
  width: 100%;
  border-radius: 10px;
}

.staff-sort {
  margin-top: 20px;
}

.staff-sort .col-lg-3 {
  width: 20%;
}

.single-staff {
  border-radius: 12px;
  background: var(--grey);
  margin-top: 25px;
  border: 1px solid var(--grey);
  position: relative;
}

.single-staff:hover {
  background: linear-gradient(var(--grey), var(--grey)) padding-box, linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%) border-box;
  border: 1px solid transparent;
}

.staff-img {
  height: 240px;
  position: relative;
}

.staff-img img {
  width: 100%;
  height: 240px;
  object-fit: cover;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}

.staff-txt {
  text-align: center;
  padding: 15px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.staff-txt h6 {
  font-size: 18px;
}

.staff-txt p {
  font-size: 14px;
  opacity: 0.7;
}

.intern-tag {
  position: absolute;
  left: 15px;
  top: 15px;
  background: var(--secondary);
  font-size: 13px;
  border-radius: 50px;
  padding: 4px 8px;
}

.single-staff.intern {
  background: linear-gradient(var(--grey), var(--grey)) padding-box, linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%) border-box;
  border: 1px solid transparent;
}

.single-staff.intern .staff-txt {
  background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
}

.single-staff-hov {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 12px;
  background: rgba(26, 25, 46, 0.70);
  backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 16px;
  transition: all 0.5s ease;
  opacity: 0;
  visibility: hidden;
}

.single-staff:hover .single-staff-hov {
  opacity: 1;
  visibility: visible;
}

.staff-name {
  align-self: stretch;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.staff-name h6 {
  font-size: 17px;
}

.staff-name p {
  font-size: 13px;
  opacity: 0.7;
  padding: 0;
}

.staff-desc p {
  font-size: 13px;
  opacity: 0.8;
  font-style: italic;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}

.staff-btn {
  align-self: stretch;
  width: 100%;
}

.staff-btn .theme-btn {
  font-size: 14px;
}

.titleBar-profile {
  display: flex;
  align-items: center;
}

.titleBar-profile-img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 10px;
}

.titleBar-profile-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avl-works {
  border-bottom: 1px solid rgba(255, 255, 255, 0.16);
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.avl-works-right {
  display: flex;
  align-items: center;
}

.modal-700 .modal-dialog {
  max-width: 700px;
}

.working-hours {
  padding: 15px 0 0 0;
}

.working-hours-single {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
}

.working-hours-left {
  display: flex;
  align-items: center;
}

.working-hours-right {
  display: flex;
  align-items: center;
}

.form {
  display: flex;
  align-items: center;
}

.to {
  display: flex;
  align-items: center;
  padding-left: 30px;
}

.working-hours-right .inp {
  max-width: 80px;
  padding: 7px 5px;
  text-align: center;
  border: 2px solid rgba(255, 255, 255, 0.16);
  background: var(--grey);
  padding-right: 32px;
  font-size: 14px;
}

.working-hours-right span {
  margin-left: 10px;
}

.working-hours-left .switch-btn+label {
  opacity: 0.7;
  font-size: 14px;
}

.form span:first-child,
.to span:first-child {
  opacity: 0.7;
  font-size: 14px;
}

.avl-works .select-style .react-select__control {
  border-radius: 12px !important;
}

.avl-works-right label {
  opacity: 0.7;
  font-size: 14px;
  padding-right: 10px;
}

.titleBar-right {
  display: flex;
  align-items: center;
}

.embed-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
  background: #37364C;
  border-radius: 6px;
  padding: 8px 15px;
}

.embed-top span {
  font-size: 14px;
  width: 16%;
}

.embed-top span:last-child {
  width: 80px;
  opacity: 0;
}

.embed-top span:first-child {
  width: 35%;
}

.embed-single {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  border: 1.5px solid rgba(255, 255, 255, 0.08);
  padding: 12px;
  margin-top: 18px;
}

.embed-single>div {
  width: 16%;
  font-size: 14px;
}

.embed-single>div:last-child {
  width: 80px;
}

.embed-single>div span {
  opacity: 0.7;
  word-break: break-all;
}

.embed-user {
  display: flex;
  align-items: center;
  width: 35% !important;
}

.embed-user-icon {
  color: var(--white);
  width: 36px;
  height: 36px;
  background: #414056;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.embed-user-txt {
  padding-left: 8px;
}

.embed-user-txt h6 {
  font-size: 14px;
  opacity: 0.7;
}

.embed-user-txt p {
  font-size: 13px;
  opacity: 0.7;
  padding: 0;
}

.active-tag {
  display: inline-block;
  background: rgba(89, 255, 165, 0.90);
  color: var(--body);
  border-radius: 50px;
  font-weight: 500;
  padding: 2px 12px;
  opacity: 1 !important;
}

.active-tag.grey {
  background: rgba(255, 255, 255, 0.10);
  color: var(--white);
  font-weight: 400;
}

.loadMore {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 45px;
  border: 2px solid rgba(255, 255, 255, 0.30);
  color: var(--white);
  padding: 7px 17px;
}

.loadMore svg {
  font-size: 20px;
  margin-right: 5px;
}

.staffBtn {
  border: 2px solid rgba(255, 255, 255, 0.08);
  background: var(--grey);
  border-radius: 50px;
  padding: 6px;
}

.staffBtn button {
  background: none;
  border: none;
  color: var(--white);
  border-radius: 50px;
  padding: 6px 15px;
}

.staffBtn button.active {
  background: var(--white);
  font-weight: 600;
}

.staffBtn button.active span {
  background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.embed-wrap {
  max-width: 900px;
  margin: 0 auto;
}

.embed-block {
  border-radius: 10px;
  background: #2A2941;
  padding: 20px;
  margin-top: 20px;
}

.embed-block-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.embed-meta {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.embed-meta>div {
  margin-right: 25px;
  display: inline-flex;
  align-items: center;
}

.embed-meta span.embed-meta-txt {
  opacity: 0.7;
  font-size: 14px;
}

.embed-meta-img {
  width: 20px;
  height: 20px;
  margin-right: 7px;
  font-size: 18px;
  display: table;
}

.embed-meta-img img {
  width: 20px;
  height: 20px;
  object-fit: cover;
  border-radius: 100%;
}

.embed-block-top-left h2 {
  font-size: 16px;
}

.embed-block-top-left p {
  font-size: 14px;
  opacity: 0.7;
}

.code-block {
  background: rgba(255, 255, 255, 0.08);
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
}

.code-block p {
  margin: 0;
  padding: 0;
  word-break: break-all;
  font-size: 14px;
}

.confirmation-cont h5 {
  font-size: 20px;
}

.confirmation-cont p {
  opacity: 0.7;
}

.modalClose.abs {
  position: absolute;
  right: 10px;
  top: 4px;
}

.backBtn.alt {
  display: flex;
  align-items: center;
}

.backBtn.alt h6 {
  font-weight: 600;
  padding-left: 10px;
}

.embed-progress {
  position: fixed;
  width: 100%;
  left: 0;
  top: 70px;
  height: 5px;
  z-index: 99;
}

.embed-progress-in {
  position: absolute;
  width: 50%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
}

.navStyle {
  margin: 0 -5px;
  margin-top: 32px;
}

.navStyle .nav-item {
  width: 50%;
  padding: 0 5px;
}

.navStyle .nav-item a {
  border-radius: 12px;
  border: 2px solid rgba(255, 255, 255, 0.12);
  color: var(--white);
  padding: 15px 25px;
}

.navStyle .nav-item a h6 {
  font-weight: 600;
  font-size: 16px;
}

.navStyle .nav-item a p {
  opacity: 0.7;
  font-size: 13px;
}

.navStyle .nav-item a.active {
  background: linear-gradient(var(--grey), var(--grey)) padding-box, linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%) border-box;
  border: 2px solid transparent;
}

.width-600 {
  max-width: 600px;
}

.create-btn .dropdown-toggle::after {
  display: none;
}

.create-btn .dropdown-menu {
  border-radius: 14px;
  background: var(--grey);
  padding: 4px;
  min-width: 220px;
  left: inherit !important;
  right: 0 !important;
  margin-top: 10px !important;
}

.create-btn .dropdown-menu a {
  border-radius: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
}

.create-btn .dropdown-menu a:hover {
  background: #3B3B52 !important;
}

.create-btn .dropdown-menu a h6 {
  font-size: 14px;
  font-weight: 500;
  color: var(--white);
}

.create-btn .dropdown-menu a p {
  font-size: 12px;
  color: var(--white);
  margin: 0;
  padding: 0;
  opacity: 0.6;
}

.create-icon {
  width: 30px;
  height: 30px;
  border-radius: 40px;
  background: rgba(255, 255, 255, 0.10);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
  color: var(--white);
}

.for-check {
  border-radius: 7px;
  border: 2px solid rgba(255, 255, 255, 0.12);
  color: var(--white);
  padding: 15px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.for-check label {
  margin: 0;
  padding: 0;
}

.inp-group {
  display: flex;
  align-items: center;
}

.custom-radio {
  margin-left: 10px !important;
  position: relative;
  display: inline-flex !important;
  align-items: center;
  background: #2E2D42;
  border-radius: 50px;
  padding: 7px 15px !important;
}

.custom-radio input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
}

.custom-radio span {
  order: 2;
}

.custom-radio .checkmark {
  background: #FFFFFF26;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  order: 1;
  margin-right: 5px;
}

.custom-radio .checkmark svg {
  transform: translateX(-1px);
}

.custom-radio input:checked~.checkmark {
  background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
}

.dir-folder ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.dir-folder ul li {
  border: 2px solid rgba(255, 255, 255, 0.16);
  border-radius: 50px;
  padding: 8px 18px;
  display: inline-flex;
  align-items: center;
  margin-right: 5px;
  margin-bottom: 10px;
}

.dir-folder ul li.active {
  background: var(--white);
}

.dir-folder ul li.active {
  color: #000;
}

.dir-folder ul li.active span {
  background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 500;
}

.dir-folder ul li svg {
  font-size: 20px;
  margin-left: 5px;
}

.department-bottom h6 {
  display: flex;
  align-items: center;
  font-size: 14px;
  opacity: 0.7;
  padding: 7px 0 0 0;
}

.department-bottom h6 svg {
  font-size: 18px;
  margin-right: 5px;
}

.department-single.alt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.department-single.alt>* {
  align-self: stretch;
  width: 100%;
  margin: 0 !important;
  padding: 0;
}

.navStyle.third .nav-item {
  width: 33.33%;
}

.width-700 {
  max-width: 700px;
}

.shared {
  border-top: 1px solid rgba(255, 255, 255, 0.16);
  margin-top: 30px;
}

.shared-single {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--grey);
  border-radius: 12px;
  padding: 19px 16px;
  margin-top: 20px;
  position: relative;
  overflow: hidden;
}

.shared-left {
  display: flex;
  align-items: center;
}

.shared-left h6 {
  font-size: 15px;
}

.shared-left p {
  font-size: 13px;
  opacity: 0.7;
  padding: 0;
}

.shared-txt {
  padding-left: 10px;
}

.shared-right span {
  font-size: 20px;
}

.uploadBar {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
}

.uploadBar-in {
  width: 50%;
  height: 5px;
  background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
  border-radius: 50px;
}

.shared-right span {
  cursor: pointer;
  margin-left: 5px;
}

.inp-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inp-flex .inp-outer {
  width: 100%;
  margin-right: 15px;
}

.shared-right {
  white-space: nowrap;
}

.titleBar-right .inp {
  padding-top: 7px;
  padding-bottom: 7px;
}

.chat-box {
  background: var(--grey);
  border-radius: 12px;
  margin-top: 30px;
  border: 2px solid var(--body);
}

.chat-box:hover {
  background: linear-gradient(var(--grey), var(--grey)) padding-box, linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%) border-box;
  border: 2px solid transparent;
}

.chat-box-top {
  background: #313148;
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.chat-box-bottom {
  padding: 20px;
  text-align: center;
}

.chat-box-bottom h6 {
  font-size: 16px;
  font-weight: 500;
}

.chat-box-bottom p {
  font-size: 14px;
  opacity: 0.7;
}

.chat-box-img {
  width: 68px;
  height: 68px;
  border-radius: 100%;
  overflow: hidden;
}

.chat-box-img img {
  width: 68px;
  height: 68px;
  object-fit: cover;
}

.chat-box-icon {
  width: 68px;
  height: 68px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(var(--grey), var(--grey)) padding-box, linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%) border-box;
  border: 2px solid transparent;
  border-radius: 100%;
  margin: 0 0 0 -25px;
  color: var(--white);
  font-size: 30px;
}

.chat-box-top-in {
  display: flex;
  align-items: center;
}

.chat-header-left {
  display: flex;
  align-items: center;
}

.chat-header-left h6 {
  font-weight: 500;
  padding-left: 10px;
}

.icon-back {
  font-size: 22px;
}

.drop-icon {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 2px solid #363547;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
}

.user-nav .dropdown-menu {
  color: var(--white);
}

.user-nav.history .dropdown-menu {
  width: 300px;
}

.flexLink {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 38px;
  background: var(--charcoal);
  color: var(--white);
  border-radius: 50px;
  font-size: 14px;
  padding: 0 10px;
  letter-spacing: -0.14px;
}

.flexLink:hover {
  background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
}

.flexLink.active {
  background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
  color: #fff;
}

.flexLink p {
  width: 210px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 13px;
}

.flexLink-icon {
  display: inline-flex;
  align-items: center;
}

.flexLink-icon button {
  border: none;
  background: none;
  color: var(--white);
  font-size: 18px;
}

.chat-header-right {
  display: flex;
  align-items: center;
}

.userList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--white);
}

.userList-left {
  display: flex;
  align-items: center;
  width: calc(100% - 40px);
}

.userList-img {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  overflow: hidden;
}

.userList-img img {
  width: 36px;
  height: 36px;
  object-fit: cover;
}

.userList-txt {
  padding-left: 8px;
}

.userList-txt h5 {
  font-size: 15px;
  font-weight: 500;
}

.userList-txt p {
  font-size: 13px;
  opacity: 0.7;
}

.user-nav.history .drop-style .dropdown-menu {
  width: 150px;
}

.user-nav.history .drop-style .dropdown-menu ul li a:hover {
  color: var(--white);
}

.user-nav.history h6 {
  color: var(--white);
  font-size: 15px;
  font-weight: 500;
}

.user-nav.history h6 span {
  font-weight: 400;
  opacity: 0.7;
}

.user-nav.history .dropdown-menu ul li {
  margin-top: 20px;
}

.user-nav.history .dropdown-menu ul li:first-child {
  margin-top: 0;
}

.user-nav.history .dropdown-menu h5 {
  font-size: 17px;
}

.user-nav.history {
  margin-left: 8px;
}

.chat-mode {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--white);
  color: var(--charcoal);
  border-radius: 50px;
  border: 2px solid #363547;
  font-weight: 600;
  padding: 0 20px;
}

.chat-mode svg {
  font-size: 22px;
  margin-right: 5px;
  color: var(--secondary);
}

.site-wrapper.forChat {
  padding-top: 75px;
  padding-bottom: 0;
  min-height: 100px;
  height: 100vh;
}

.chat-container {
  max-width: 1200px;
  margin: 0 auto;
}

.chatscreen-bottom {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 99;
  padding-bottom: 30px;
  background: var(--body);
}

.chat-icon {
  position: absolute;
  right: 15px;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}

.chat-icon span {
  margin-left: 5px;
}

.chat-info {
  text-align: center;
  padding-top: 15px;
  color: #98989b;
}

.messages {
  height: calc(100vh - 193px);
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 40px;
}

.messages::-webkit-scrollbar {
  display: none;
}

.message-group {
  display: inline-block;
  min-width: 330px;
  max-width: 650px;
  width: 650px;
  position: relative;
  padding-left: 50px;
}

.message-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 42px 0 0;
  font-weight: 500;
}

.user-photo {
  position: absolute;
  left: 0;
  top: 0;
  width: 34px;
  height: 34px;
  border-radius: 100%;
}

.user-photo img {
  width: 34px;
  height: 34px;
  object-fit: cover;
  border-radius: 100%;
}

.message-body {
  margin-top: 10px;
  position: relative;
  padding: 0 40px 0 0;
}

.message-txt {
  background: var(--white);
  color: var(--crayola);
  font-weight: 500;
  border-radius: 8px;
  padding: 8px 15px;
  width: calc(100% - 4px);
  transition: none !important;
}

.message-txt p {
  font-size: 14px;
  padding-top: 15px;
  /* word-break: break-all; */
}

.message-txt p:first-child {
  padding-top: 0;
}

.user-photo-mark {
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 20px;
  height: 20px;
}

.user-photo-mark img {
  width: 20px !important;
  height: 20px !important;
}

.message-body .user-nav {
  margin: 0;
  position: absolute;
  top: 0;
  right: 0;
}

.message-body .user-nav .drop-icon {
  border: none;
  font-size: 24px;
  opacity: 0.6;
}

.message-wrap {
  margin-top: 30px;
}

.msg-tags {
  margin-top: 10px;
  font-size: 13px;
  display: flex;
  align-items: center;
}

.msg-tags span {
  display: flex;
  align-items: center;
  border-radius: 100px;
  background: #3F3F59;
  height: 28px;
  padding: 0 10px;
  margin-left: 10px;
}

.msg-tags span svg {
  margin-right: 5px;
}

.own-message {
  display: flex;
  justify-content: flex-end;
}

.own-message .message-txt {
  background: #7737D9;
  color: var(--white);
}

.own-message .user-photo {
  left: inherit;
  right: 0;
}

.own-message .message-group {
  padding-left: 0;
  padding-right: 50px;
}

.own-message .message-body .user-nav {
  right: inherit;
  left: 0;
}

.own-message .message-body {
  padding-right: 0;
  padding-left: 40px;
}

.own-message .message-head {
  padding-right: 0;
  padding-left: 42px;
  flex-direction: row-reverse;
}

.own-message .msg-tags {
  padding-left: 40px;
}

.inp-icon .user-nav {
  margin: 0;
}

.inp-icon .drop-icon {
  width: auto;
  height: auto;
  border: none;
  font-size: 22px;
  margin-top: 5px;
}

.inp-icon .user-nav .dropdown-menu {
  min-width: 220px;
  margin: 0 !important;
  transform: translateY(-50px) !important;
  background: #27273E !important;
  padding: 4px;
}

.act-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border-radius: 10px;
  cursor: pointer;
}

.act-menu:hover {
  background: #3B3B52;
}

.act-menu-txt {
  width: calc(100% - 36px);
  padding-left: 10px;
}

.act-menu-txt h6 {
  font-size: 15px;
}

.act-menu-txt p {
  font-size: 13px;
  opacity: 0.7;
  font-weight: 300;
  padding: 0;
}

.message-image-single {
  height: 130px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  margin-top: 6px;
}

.message-image-single img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.message-image-opt {
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(18, 26, 25, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}

.message-image-opt .embed-user-icon {
  margin: 0 5px;
  cursor: pointer;
}

.message-images {
  max-width: 480px;
}

.message-images .row {
  /* margin: 0 -3px; */
}

.message-images .col-6 {
  padding-left: 3px;
  padding-right: 3px;
}

.message-image-single:hover .message-image-opt {
  opacity: 1;
  visibility: visible;
}

.message-image-single.img-full {
  height: 300px;
}

.no-bg .modal-content {
  background: none !important;
}

.no-bg .modal-body {
  padding: 0;
}

.modal-img {
  border-radius: 10px;
  overflow: hidden;
  margin-top: 30px;
}

.modal-img img {
  width: 100%;
}

.modal-img-download {
  text-align: center;
  margin-top: 20px;
}

.closePrev {
  text-align: right;
  padding-bottom: 30px;
}

.closePrev span {
  display: inline-block;
  cursor: pointer;
}

.closePrev svg {
  font-size: 22px;
  margin-left: 8px;
}

.chat-header-right .user-nav {
  margin-left: 8px;
}

.user-nav.forClear .dropdown-menu ul li svg {
  font-size: 18px;
  margin-right: 5px;
}

.drop-icon.toggle {
  display: none;
}

.chatheader-close {
  display: none;
}

.message-wrap.auto-message .message-group {
  width: 804px;
  max-width: none;
}

.message-wrap.auto-message .message-txt {
  background: #2E2D42;
  color: var(--white);
}

.auto-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.auto-header h3 {
  font-size: 16px;
  font-weight: 500;
}

.auto-header .user-nav {
  position: relative;
}

.auto-header .user-nav .drop-icon {
  width: auto;
  font-size: 14px;
  border: 2px solid #FFF;
  border-radius: 50px !important;
  padding: 4px 12px;
  height: auto;
}

.auto-header .user-nav .drop-icon svg {
  font-size: 22px;
}

.auto-message .theme-accord {
  margin-bottom: 10px;
}

.auto-message .theme-accord .accordion-item {
  background: #38374C !important;
}

.auto-message .theme-accord .accordion-item:has(> .accordion-collapse.show) {
  background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%) !important;
}

.auto-message .theme-accord .accordion-collapse.show p {
  opacity: 1 !important;
}

.auto-message .theme-accord .accordion-header button>div span {
  display: block;
}

.auto-message .theme-accord .accordion-button::after {
  background: url('../src/assets/images/down.png') no-repeat !important;
}

.auto-message .theme-accord .accordion-header button p {
  text-transform: uppercase;
  opacity: 0.7;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.auto-message .theme-accord .accordion-header button p img {
  margin-left: 5px;
}

.typing {
  margin-top: 15px;
  margin-bottom: 15px;
  background: #38374C !important;
  border-radius: 12px !important;
  padding: 10px 20px;
}

.typing p {
  text-transform: uppercase;
  opacity: 0.7;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.typing-div h6 {
  font-size: 14px;
  padding-left: 10px;
}

.task-addition {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}

.task-addition .inp {
  width: 100px;
  text-align: center;
  margin: 0 20px;
}

.account-pic-upload {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 40px;
  height: 40px;
  background: #000;
  border-radius: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  border: 2px solid #FFF;
}

.account-pic-upload input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 5;
  opacity: 0;
}

.integrations-in {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.integrations-single {
  flex-direction: column;
}

.integrations-in+.integrations-in {
  border-top: 1px solid #e9e9e9;
  margin: 10px 0 0 0;
  padding: 10px 0 0 0;
}

.integration-img {
  width: 42px;
  height: 42px;
  ;
}

.integration-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.theme-btn.sm {
  padding: 5px 12px;
  font-size: 13px;
}


.support-vid-single {
  background: var(--grey);
  border-radius: 12px;
  margin-top: 30px;
  overflow: hidden;
  cursor: pointer;
}

.support-txt {
  padding: 15px;
}

.support-video-thumb {
  height: 200px;
}

.support-video-thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.nav-switch {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-switch .nav {
  display: inline-flex;
  padding: 5px 6px;
  border-radius: 100px;
  border: 2px solid rgba(255, 255, 255, 0.08);
  background: #E7E7E7;
}

.nav-switch .nav-link {
  border-radius: 100px;
  padding: 8px 12px;
  color: #1A192E;
  font-weight: 600;
}

.nav-switch .nav-link span {
  display: block;
}

.nav-switch .nav-link.active {
  background: #FFF;
}

.nav-switch .nav-link.active span {
  background: linear-gradient(90deg, #9461FC 0.08%, #EF1564 101.7%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.navStyle-3 .nav-item {
  width: 33.33%;
}

.company-empty.large {
  max-width: 800px;
}

.doc-list {
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
  padding: 25px;
  position: relative;
  overflow: hidden;
}

.doc-icon {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background: #4140562E;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #1A192E;
}

.doc-list h6 {
  font-size: 15px;
  font-weight: 600;
  color: #1A192E;
}

.doc-list p {
  font-size: 14px;
  font-weight: 500;
  color: #1A192E;
  opacity: 0.7;
}

.uploadBar {
  position: absolute;
  left: 0;
  top: 0;
  height: 4px;
  background: linear-gradient(90deg, #9461FC 0.08%, #EF1564 101.7%);
}

.icongroup {
  display: flex;
  align-items: center;
  gap: 10px;
}

.icongroup span {
  font-size: 20px;
  cursor: pointer;
}


.payment-provider {
  background: #FFFFFF;
  border-radius: 12px;
  border: 0.6px solid rgba(0, 0, 0, 0.10);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
}

.payment-provider-logo {
  width: 40px;
}

.payment-provider-single {
  padding: 20px;
  border-top: 1px solid #ededed;
}

.payment-provider-single:first-child {
  border: none;
}

.payment-provider-single h6 {
  font-size: 14px;
  font-weight: 600;
}

.payment-provider-single p {
  font-size: 13px;
  opacity: 0.7;
}


.cr-staff .user-nav .dropdown-menu {
  width: max-content;
}

.department-top .embed-user-icon {
  cursor: pointer;
}

.titleBar-left.full {
  width: 100%;
}

.navStyle-short {
  justify-content: center;
  padding-top: 30px;
}

.navStyle-short-wrap {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #E7E7E7;
  border-radius: 50px;
  padding: 8px;
}

.navStyle-short .nav-link {
  border-radius: 50px;
  color: #1A192E;
  font-weight: 600;
  border: 1px solid #E7E7E7;
}

.navStyle-short .nav-link.active {
  background: #FFF;
  border: 1px solid rgba(0, 0, 0, 0.10);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
  color: var(--secondary);
}

.embed-user-image {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  overflow: hidden;
}

.embed-user-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.embed-user-icon.fill {
  background: rgba(65, 64, 86, 0.18);
  border: none;
}

.or-style {
  position: relative;
  text-align: center;
}

.or-style::after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
  background: #DDD;
}

.or-style span {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #DDD;
  background: #FFF;
  border-radius: 50px;
  font-weight: 500;
  padding: 5px 30px;
  position: relative;
  z-index: 2;
}

.inp-outer.round {
  border-radius: 50px;
}

.inp-outer.round .inp {
  border-radius: 50px;
}

.inp.sm {
  padding-top: 7px;
  padding-bottom: 7px;
}

.chat-box-top {
  position: relative;
}

.chat-box-top .user-nav {
  position: absolute;
  margin: 0;
  padding: 0;
  top: 15px;
  right: 15px;
}

.chat-box-top .drop-icon {
  width: 28px;
  height: 28px;
  background: #d5d5d5;
  border: none;
}

/* .mentionTag{ position: absolute; height: 100%; left: 42px; top: 0;} */
.added-staff-batch {
  position: static !important;
  white-space: nowrap;
}

.embed-single.auto>div {
  width: unset !important;
}

.embed-user-icon.no-bdr {
  border: none !important;
  width: unset !important;
}

.user-nav.alt .dropdown-menu ul li a {
  border-radius: 5px;
}

.user-nav .dropdown-menu ul li {
  margin-top: 6px;
}

.user-nav .dropdown-menu {
  min-width: max-content !important;
}

.act-menu.sm {
  padding: 3px;
  white-space: nowrap;
}

.act-menu.sm .act-menu-txt h6 {
  font-size: 12px;
}

.act-menu.sm .embed-user-icon {
  font-size: 15px;
  width: 30px;
  height: 30px;
}

.mention-inp {
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 7px;
  color: var(--white);
  font-size: 15px;
  font-weight: 400;
  padding: 2px;
  background: #FFF !important;
  border: 2px solid #d9d9d9;
  padding-left: 42px;
}

.mention-inp .inp.alt {
  padding-left: 10px;
}

.mention-inp textarea.inp {
  height: 44px;
  padding-right: 100px;
}

.auto-input {
  background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%) !important;
  display: flex;
  align-items: center;
}

.auto-input-icon {
  background: #FFF;
  height: 42.5px;
  display: flex;
  align-items: center;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  padding: 0 10px;
  border-right: 1px solid #dfdfdf;
  gap: 0 6px;
}

.auto-input .inp {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.auto-input-icon .inp-icon {
  position: static;
  transform: none;
  font-size: unset;
  display: block;
}

.auto-input-icon .drop-icon {
  margin: 0 !important;
  width: 34px;
  height: 34px;
  background: #d1d1d1;
}

.auto-input-icon .user-nav {
  margin: 0;
}

.taskInp {
  display: flex;
  align-items: center;
  gap: 0 5px;
  padding-left: 10px;
}

.taskInp label {
  margin: 0;
  padding: 0;
  font-weight: 500;
}

.taskInp input {
  border: 1px solid #d9d9d9;
  width: 50px;
  text-align: center;
  padding: 5px 0;
  border-radius: 5px;
}

.drop-icon.auto {
  background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%) !important;
  color: #FFF !important;
  border: none !important;
}

.inp-iconN {
  position: relative;
}

.custom-tool {
  position: absolute;
  left: 0;
  bottom: calc(100% + 12px);
  background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%) !important;
  color: #FFF !important;
  border-radius: 12px;
  padding: 12px;
  width: max-content;
  display: flex;
  align-items: center;
  gap: 0 10px;
  opacity: 0;
  visibility: hidden;
}

.custom-tool::after {
  content: '';
  position: absolute;
  left: 10px;
  bottom: -10px;
  width: 0px;
  height: 0px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 10px solid var(--primary);
}

.custom-tool h5 {
  font-size: 16px;
}

.custom-tool h6 {
  font-size: 12px;
}

.custom-tool-hover:hover .custom-tool {
  opacity: 1;
  visibility: visible;
}

.footer-left ul {
  display: flex;
  align-items: center;
  gap: 0 10px;
}

.footer-left ul li {
  font-size: 14px;
}

.footer-left ul li a {
  font-size: 14px;
  color: var(--dark-blue);
}

.footer-left ul li a:hover {
  color: blue;
}

.footer-mid p {
  margin: 0;
  padding: 0;
  text-align: center;
}

.footer-mid p a {
  color: blue;
}

.reel-logo {
  max-width: 210px;
  height: 100%;
}
.reel-logo a img{
  height: 100%  !important;
  object-fit: contain;
}

.company-link .dropdown-menu ul li a img {
  width: 22px;
  height: 22px;
  border-radius: 100%;
  object-fit: cover;
}

.company-link .dropdown-menu ul li a svg {
  width: 20px;
  height: 20px;
}

.user-nav.history .dropdown-menu ul li {
  margin-top: 6px;
}

.user-nav .dropdown-menu {
  max-height: calc(100vh - 100px);
  overflow-x: hidden;
  overflow-y: auto;
}

.user-nav.history.full .dropdown-menu {
  width: 350px !important;
  overflow: unset !important;
}

.user-nav.history.full .dropdown-menu .dropdown-menu {
  width: max-content !important;
}

.added-staff-batch {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.company-opt-img img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 100%;
}

.company-opt .theme-btn svg {
  font-size: 15px;
}

.company-opt .theme-btn {
  padding: 3px 10px;
}


.modal-backdrop {
  z-index: 999999 !important;
}

.modal {
  z-index: 9999999 !important;
}

.drop-style .dropdown-menu {
  width: max-content;
}

.user-nav.history.CS .dropdown-menu {
  width: 250px !important;
  min-width: unset !important;
}

.modalClose {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  z-index: 9999999999;
}


.opt-file-upload {
  height: 200px;
  border: 1px dashed #b8b8b8;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.opt-file-upload input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 5;
  opacity: 0;
}

select.inp {
  appearance: none;
  background: url('./assets/images/down-2.png') no-repeat right 15px center #FFFFFF !important;
}

.list-item {
  background: #f7f7f7;
  padding: 20px;
  border-radius: 15px;
}

.list-item li {
  position: relative;
  margin-top: 10px;
  padding: 0 0 0 18px;
}

.list-item li::after {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: #000;
  position: absolute;
  left: 0;
  top: 6px;
}

.chat-container-top {
  display: flex;
  align-items: center;
  gap: 0 10px;
}

.chat-container-left {
  flex: 1;
}


.message-images.sm {
  max-width: 70px;
}

.message-images.sm .message-image-single {
  height: unset;
}

.message-images.sm .message-image-single img {
  width: auto;
  height: auto;
}


.record-action {
  background: #f5f5f5;
  border: 1px solid #e5e5e5;
  border-radius: 12px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
  padding: 16px;
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 15px;
}

.embed-single.count-2 {
  justify-content: space-between;
}

.embed-single.count-2>div {
  width: unset !important;
}

.staff-sort .inp {
  padding-top: 6px;
  padding-bottom: 6px;
}

.attach-img-wrap {
  border: 2px solid #d9d9d9;
  padding: 10px;
  background: #FFF;
  border-radius: 7px;
}

.attach-img {
  max-width: max-content;
  max-height: 100px;
  position: relative;
}

.attach-img img {
  border-radius: 10px;
  max-height: 100px;
}


.payment-opt {
  display: flex;
  align-items: center;
  gap: 0 10px;
}

.payment-opt-single {
  width: 33.33%;
  padding: 15px;
  border-radius: 10px;
  border: 2px solid #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  position: relative;
  height: 80px;
}

.payment-opt-single input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9;
  opacity: 0;
  cursor: pointer;
}

.payment-logo img {
  max-height: 28px;
}

.payment-opt-single h6 {
  font-weight: 700;
  padding-top: 8px;
}

.payment-opt-single:has(input:checked) {
  border: 2px solid var(--primary);
}

.table-icon {
  cursor: pointer;
  margin: 0 3px !important;
  padding: 0 !important;
  font-size: 20px;
}

.flImg {
  width: auto !important;
  max-width: 185px !important;
  height: 50px !important;
}

.flImg img {
  max-width: 100% !important;
  max-height: 50px !important;
}

.addStaff {
  border: 2px solid #e9e9e9;
  border-radius: 12px;
  padding: 10px;
}

.addStaff-img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  overflow: hidden;
}

.addStaff-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* .chat-container .user-nav .dropdown-menu{ margin: -42px 0 0 36px;}
.own-message .user-nav .dropdown-menu{ left: unset; right: 0; } */

.messages .user-nav .dropdown-menu {
  margin-top: 0 !important;
}

.webnair p {
  font-size: 13px;
}

.post-block-img.alt img {
  width: auto;
  max-width: 100%;
}

.post-block.alt {
  margin-top: 30px;
  background: #fafafa;
  padding: 25px;
  border-radius: 15px;
}

.post-block.alt:first-child {
  margin-top: 0;
}



.embed-top.forEmbed span:first-child{ width: 24% !important;}
.embed-top.forEmbed span:nth-child(2){ width: 30% !important;}
.embed-list.forEmbed .embed-user{ width: 24% !important; }
.embed-list.forEmbed .embed-single > div:nth-child(2){ width: 30% !important;}
.embed-wrap.large{ max-width: 1200px;}


@media (max-width: 1599px) {
  .profile-link {
    margin-left: 60px;
  }
}

@media (max-width: 1300px) {
  .profile-link {
    margin-left: 15px;
  }

  .site-nav ul li {
    margin-left: 8px;
  }
}

@media (max-width: 1199px) {
  .site-container {
    padding: 0 15px;
  }

  .dashboard-right {
    padding-left: 15px;
  }

  .dashboard-block {
    margin-top: 15px;
  }

  .tutorial-icon {
    width: 36px;
    height: 36px;
  }

  .tutorial-details {
    width: calc(100% - 36px);
  }

  .hero-banner-left {
    max-width: 380px;
  }

  .hero-banner-right {
    width: 450px;
  }

  .banner-right-video-in {
    height: 264px;
  }

  .credit-icon {
    width: 40px;
    height: 40px;
  }

  .credit-details {
    width: calc(100% - 40px);
  }

  .profile-stat-list ul li {
    font-size: 14px;
  }

  .webnair p {
    width: 100%;
    font-size: 12px;
  }

  .photo-frame {
    width: 100%;
  }

  .tutorial-details h5 {
    font-size: 14px;
  }

  .site-wrapper {
    padding-top: 90px;
    padding-bottom: 30px;
  }

  .site-nav {
    position: fixed;
    width: 100%;
    height: 100%;
    background: var(--body);
    left: -100%;
    top: 0;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: all 0.5s ease;
  }

  .site-nav.act {
    left: 0;
  }

  .site-nav ul {
    flex-direction: column;
  }

  .site-nav ul li {
    margin: 5px 0;
  }

  .site-nav ul li a {
    width: 200px;
    justify-content: center;
  }

  .mobMenu {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .dash-row {
    margin: 0 -7px;
  }

  .dash-row [class*='col-'] {
    padding-left: 7px;
    padding-right: 7px;
  }

  .hero-banner-2 {
    margin: -17px -15px 0 -15px;
  }

  .hero-banner-2 .hero-banner-left {
    max-width: 460px;
  }

  .account-right {
    margin-left: 30px;
  }

  .account-wrap.for-post .account-left {
    width: 250px;
  }

  .tabNav .nav-link {
    font-size: 12px;
  }

  .tabNav .nav-item {
    margin-top: 5px;
  }

  .account-wrap.for-post .account-right {
    width: calc(100% - 250px);
  }

  .chat-container {
    max-width: 100%;
    padding: 0 30px;
  }

  .footer-mid p {
    font-size: 12px;
  }

  .footer-left ul li a {
    font-size: 12px;
  }

  .reel-logo {
    max-width: 170px;
  }
}

@media (max-width: 1023px) {
  .dashboard-wrap {
    flex-wrap: wrap;
  }

  .dashboard-left {
    width: 100%;
  }

  .dashboard-right {
    width: 100%;
    padding: 0;
  }

  .departments {
    height: auto;
  }

  .photo-frame {
    max-width: 350px;
  }

  .hero-banner {
    height: 300px;
    padding: 0 15px;
  }

  .hero-banner-left {
    max-width: 326px;
  }

  .hero-banner-right {
    width: 360px;
  }

  .banner-right-video-in {
    height: 203px;
  }

  .site-footer-main {
    flex-wrap: wrap;
  }

  .footer-left {
    width: 100%;
  }

  .footer-right {
    width: 100%;
    padding: 20px 0 0 0;
  }

  .footer-right ul li:first-child {
    margin-left: 0;
  }

  .site-footer-main {
    height: auto;
    padding: 15px 0;
  }

  .hero-banner-2 {
    height: 580px !important;
  }

  .hero-banner-2 .hero-banner-video video {
    height: 580px !important;
  }

  .dashboard-wrap-v6 {
    margin-top: -440px;
  }

  .titleBar {
    flex-wrap: wrap;
  }

  .titleBar-left {
    align-self: stretch;
    width: 100%;
  }

  .titleBar-right {
    margin-top: 15px;
  }

  .account-right {
    margin-left: 30px;
  }

  .account-wrap.for-post {
    flex-wrap: wrap;
  }

  .account-wrap.for-post .account-left {
    width: 100%;
  }

  .account-wrap.for-post .account-right {
    width: 100%;
    padding: 0;
    padding-top: 40px;
  }

  .tabNav .nav-link {
    font-size: 14px;
  }

  .staffBtn {
    width: 100%;
  }

  .staffBtn button {
    width: 50%;
  }

  .staff-sort .row {
    margin: 0 -5px;
  }

  .staff-sort .col-lg-3 {
    width: 50%;
    margin-top: 10px;
    padding: 0 5px;
  }

  .staff-sort .col-lg-3:first-child {
    width: 100%;
  }

  .staff-sort {
    margin-top: 10px;
  }

  .titleBar.forStaff .titleBar-right {
    align-self: stretch;
    width: 100%;
  }

  .login-wrap {
    flex-wrap: wrap;
  }

  .login-left {
    width: 100%;
    max-width: 100%;
    padding: 0;
  }

  .footer-left ul {
    justify-content: center;
  }

  .footer-mid {
    width: 100%;
    padding: 15px 0 !important;
  }

  .footer-mid p {
    text-align: center;
  }

  .footer-right {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 0 0 !important;
  }

  .login-wrap {
    align-items: unset;
  }

  .login-left {
    text-align: center;
  }

  .hero-banner-left p {
    font-size: 13px;
    line-height: 18px;
  }

  .hero-banner-left h1 {
    font-size: 28px;
  }

  .site-footer{ position: static;}
}

@media (max-width: 767px) {
  .user-nav {
    margin-left: 8px;
  }

  .mobMenu {
    margin-left: 8px;
    width: 44px;
  }

  .content-area-in {
    padding: 1rem;
  }

  .company-name {
    display: none;
  }

  .company-link .dropdown-toggle.btn::after {
    display: none;
  }

  .company-logo {
    margin-right: 0;
  }

  .hero-banner {
    flex-wrap: wrap;
  }

  .hero-banner {
    height: auto;
    padding: 15px;
  }

  .hero-banner-left {
    width: 100%;
    padding-top: 10px;
  }

  .hero-banner-right {
    width: 100%;
    margin-top: 30px;
  }

  .hero-banner-left h1 {
    font-size: 26px;
  }

  .hero-banner-left p {
    opacity: 1;
    /* font-size: 16px; */
  }

  .hero-banner-video video {
    height: 80vh;
  }

  .useage-data-single {
    flex-wrap: wrap;
    position: relative;
  }

  .useage-data-left {
    width: 100%;
  }

  .useage-data-right {
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 10px;
  }

  .useage-data-right>div {
    margin: 0;
  }

  .useage-data-right>div:last-child {
    position: absolute;
    right: 0;
    top: 15px;
  }

  .join-facebook-bar {
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
  }

  .join-facebook {
    height: auto;
    padding: 30px 15px;
  }

  .join-facebook-bar-left {
    width: 100%;
    padding-bottom: 15px;
  }

  .site-footer p {
    padding: 10px 0 0 0;
  }

  .useage-data-single:hover {
    padding-left: 0;
    padding-right: 0;
    border: none;
    background: none;
  }

  .useage-top {
    flex-wrap: wrap;
  }

  .useage-top-left {
    width: 100%;
  }

  .useage-top-right {
    width: 100%;
    margin-top: 5px;
  }

  .dashboard-wrap-v2 {
    margin-top: -150px;
  }

  .dashboard-wrap-v3 {
    margin-top: -30px;
  }

  .dashboard-wrap-v6 {
    margin-top: -350px;
  }

  .hero-banner .company-link .dropdown-toggle.btn {
    width: 100%;
  }

  .hero-banner .company-name {
    display: block;
    padding-left: 5px;
    max-width: 100%;
  }

  .hero-banner-6 .hero-banner-right {
    width: 100%;
  }

  .hero-banner-6 .company-link {
    margin-top: -80px;
  }

  .login-box {
    width: 100%;
  }

  .login-footer {
    position: static;
  }

  .login-wrap {
    padding: 72px 0;
  }

  .login-header .site-header {
    background: var(--body);
  }

  .company-block-title {
    display: block;
  }

  .company-block-title .theme-btn {
    width: 100%;
    margin-top: 15px;
  }

  .webnair-block .col-sm-6+.col-sm-6 {
    order: 1;
  }

  .webnair-block .col-sm-6 {
    order: 2;
  }

  .webnair-block-right {
    text-align: center;
    padding-bottom: 15px;
  }

  .facebook-block .col-sm-6+.col-sm-6 {
    order: 1;
  }

  .facebook-block .col-sm-6 {
    order: 2;
  }

  .facebook-block-right {
    text-align: center;
    padding-bottom: 15px;
  }

  .company-opt ul li {
    flex-wrap: wrap;
    height: auto;
    padding: 8px 15px;
  }

  .company-opt-left {
    align-self: stretch;
    width: 100%;
  }

  .company-opt-right {
    align-self: stretch;
    width: 100%;
    padding: 15px 0 0 0;
  }

  .company-opt-right .theme-btn {
    width: 100%;
  }

  .titleBar-right {
    display: block;
    align-self: stretch;
    width: 100%;
  }

  .titleBar-right>* {
    margin: 8px 0 0 0 !important;
  }

  .sm-w-100 {
    width: 100%;
  }

  .account-wrap {
    flex-wrap: wrap;
  }

  .account-left {
    align-self: stretch;
    width: 100%;
  }

  .account-right {
    align-self: stretch;
    width: 100%;
    margin: 0;
  }

  .account-nav ul li a {
    margin: 0;
    display: none;
  }

  .account-nav ul li a.active {
    display: flex;
  }

  .integrations-single {
    flex-wrap: wrap;
  }

  .integration-left {
    align-self: stretch;
    width: 100%;
  }

  .integration-right {
    align-self: stretch;
    width: 100%;
    padding: 15px 0 0 0;
  }

  .integration-right .theme-btn {
    width: 100%;
  }

  .titleBar-left h2 {
    font-size: 20px;
  }

  .manage-block-single {
    flex-wrap: wrap;
  }

  .manage-block-left {
    align-self: stretch;
    width: 100%;
    align-items: flex-start;
  }

  .manage-block-txt {
    width: calc(100% - 42px);
  }

  .group-icon {
    padding-left: 52px;
  }

  .avl-works {
    flex-wrap: wrap;
  }

  .avl-works-left {
    align-self: stretch;
    width: 100%;
  }

  .avl-works-right {
    align-self: stretch;
    width: 100%;
    padding-top: 15px;
  }

  .working-hours-single {
    flex-wrap: wrap;
  }

  .working-hours-left {
    align-self: stretch;
  }

  .to {
    padding-left: 0;
  }

  .form,
  .to {
    flex-wrap: wrap;
  }

  .form span:first-child,
  .to span:first-child {
    align-self: stretch;
    width: 100%;
  }

  .working-hours-right .inp {
    width: 65px;
    min-width: 10px;
  }

  .working-hours-right span {
    margin: 0 3px;
  }

  .working-hours-right {
    padding-top: 10px;
  }

  .modalClose {
    position: absolute;
    top: -6px;
    right: 10px;
  }

  .embed-top {
    display: none;
  }

  .embed-single {
    flex-wrap: wrap;
  }

  .embed-user {
    align-self: stretch;
    width: 100% !important;
  }

  .embed-single>div:nth-child(2) {
    padding-top: 10px;
  }

  .embed-single>div:nth-child(3) {
    padding-bottom: 10px;
  }

  .embed-single>div:nth-child(2),
  .embed-single>div:nth-child(3) {
    align-self: stretch;
    width: 100% !important;
  }

  .embed-single>div:nth-child(4) {
    width: 200px !important;
  }

  .embed-meta {
    flex-wrap: wrap;
  }

  .embed-meta>div {
    margin-top: 5px;
  }

  .navStyle .nav-item {
    width: 100%;
    margin: 3px 0;
  }

  .navStyle.third .nav-item {
    width: 100%;
  }

  .shared-single {
    flex-wrap: wrap;
  }

  .shared-left {
    align-self: stretch;
    width: 100%;
  }

  .shared-right {
    padding-left: 40px;
    padding-top: 10px;
  }

  .chat-container {
    padding: 0 10px;
  }

  .message-image-single {
    height: 70px;
  }

  .message-image-single.img-full {
    height: 134px;
  }

  .message-txt p {
    font-size: 13px;
  }

  .chatscreen-bottom {
    padding-bottom: 10px;
  }

  .chat-header-left .user-stack-single:first-child,
  .chat-header-left .user-stack-single:nth-child(2) {
    display: none;
  }

  .chat-header-left .user-stack {
    margin-left: 15px;
  }

  .chat-header-right {
    transition: all 0.5s ease;
    position: fixed;
    width: 100%;
    height: 100%;
    left: -100%;
    top: 0;
    justify-content: center;
    align-items: flex-start;
    background: rgba(26, 25, 46, 0.85);
    backdrop-filter: blur(10px);
    z-index: 999;
    padding-top: 50px;
  }

  .chat-header-right.act {
    left: 0;
  }

  .site-header {
    z-index: 999;
  }

  .chat-header-left h6 {
    padding-left: 3px;
    font-size: 14px;
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .chat-info {
    padding-top: 6px;
  }

  .drop-icon.toggle {
    display: flex !important;
  }

  .chatheader-close {
    display: block;
    position: absolute;
    right: 15px;
    top: 5px;
    font-size: 22px;
  }

  .user-nav.history .dropdown-menu {
    width: calc(100vw - 30px);
    left: 15px !important;
    position: fixed !important;
    transform: none !important;
    top: 80px !important;
  }

  .login-wrap {
    padding-left: 15px;
    padding-right: 15px;
  }

  .login-left {
    padding: 40px 0;
  }

}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 1000ms linear infinite;
}



.loader {
  width: 80px;
  height: 80px;
  border: 3px var(--secondary) solid;
  border-radius: 50%;
  animation: rotation 1s ease-in-out infinite;
  text-align: center;
}

.loader-sec {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background-color: #ffffff; */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.loader::after {
  width: 15px;
  height: 15px;
  background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
  border-radius: 100%;
  position: absolute;
  content: "";
}

@keyframes rotation {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.staff-toggle-box {
  min-height: 50px;
  position: absolute;
  background: #efeeee;
  bottom: 48px;
  left: 2px;
  width: 250px;
  border-radius: 20px;
}

.userToogleList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--white);
  margin-bottom: 5px;
  cursor: pointer;
  transition: none;
}

.userToogleList-left {
  display: flex;
  align-items: center;
  width: calc(100% - 40px);
}

.userToogleList-img {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  overflow: hidden;
}

.userToogleList-img img {
  width: 36px;
  height: 36px;
  object-fit: cover;
}

.userToogleList-txt {
  padding-left: 8px;
}

.userToogleList-txt h5 {
  font-size: 15px;
  font-weight: 500;
}

.userToogleList-txt p {
  font-size: 13px;
  opacity: 0.7;
}

.userToogleList:hover {
  background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
  border-radius: 20px;
}

.added-staff-batch {
  position: absolute;
  top: 0;
  left: 0;
  padding: 2px 3px;
  color: #fff;
  border-radius: 20px;
  display: flex;
  align-items: center;
  height: 50%;
  background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
  ;
}


.blink-typing {
  display: flex;
  justify-content: center;
  align-items: end;
  background: transparent;
  margin-top: 15px;
}

.blink-typing span {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #000;
  animation: blink_typing 1s infinite;
  animation-fill-mode: both;
  display: inline-block;
  margin-left: 2px;
}

.blink-typing span:nth-child(2) {
  animation-delay: 0.2s;
  margin-left: 5px;
}

.blink-typing span:nth-child(3) {
  animation-delay: 0.4s;
  margin-left: 5px;
}

@keyframes blink_typing {
  0% {
    opacity: 0.1;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.1;
  }
}

.swatch-cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0
}

.sketch-picker {
  position: absolute;
  z-index: 999;
}